var materialThemeLighter = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#80CBC4",
    "activityBar.background": "#FAFAFA",
    "activityBar.border": "#FAFAFA60",
    "activityBar.dropBackground": "#E5393580",
    "activityBar.foreground": "#90A4AE",
    "activityBarBadge.background": "#80CBC4",
    "activityBarBadge.foreground": "#000000",
    "badge.background": "#CCD7DA30",
    "badge.foreground": "#90A4AE",
    "breadcrumb.activeSelectionForeground": "#80CBC4",
    "breadcrumb.background": "#FAFAFA",
    "breadcrumb.focusForeground": "#90A4AE",
    "breadcrumb.foreground": "#758a95",
    "breadcrumbPicker.background": "#FAFAFA",
    "button.background": "#80CBC440",
    "button.foreground": "#ffffff",
    "debugConsole.errorForeground": "#E53935",
    "debugConsole.infoForeground": "#39ADB5",
    "debugConsole.warningForeground": "#E2931D",
    "debugToolBar.background": "#FAFAFA",
    "diffEditor.insertedTextBackground": "#39ADB520",
    "diffEditor.removedTextBackground": "#FF537020",
    "dropdown.background": "#FAFAFA",
    "dropdown.border": "#00000010",
    "editor.background": "#FAFAFA",
    "editor.findMatchBackground": "#00000020",
    "editor.findMatchBorder": "#80CBC4",
    "editor.findMatchHighlight": "#90A4AE",
    "editor.findMatchHighlightBackground": "#00000010",
    "editor.findMatchHighlightBorder": "#00000030",
    "editor.findRangeHighlightBackground": "#E2931D30",
    "editor.foreground": "#90A4AE",
    "editor.lineHighlightBackground": "#CCD7DA50",
    "editor.lineHighlightBorder": "#CCD7DA00",
    "editor.rangeHighlightBackground": "#FFFFFF0d",
    "editor.selectionBackground": "#80CBC440",
    "editor.selectionHighlightBackground": "#27272720",
    "editor.wordHighlightBackground": "#FF537030",
    "editor.wordHighlightStrongBackground": "#91B85930",
    "editorBracketMatch.background": "#FAFAFA",
    "editorBracketMatch.border": "#27272750",
    "editorCursor.foreground": "#272727",
    "editorError.foreground": "#E5393570",
    "editorGroup.border": "#00000020",
    "editorGroup.dropBackground": "#E5393580",
    "editorGroup.focusedEmptyBorder": "#E53935",
    "editorGroupHeader.tabsBackground": "#FAFAFA",
    "editorGutter.addedBackground": "#91B85960",
    "editorGutter.deletedBackground": "#E5393560",
    "editorGutter.modifiedBackground": "#6182B860",
    "editorHoverWidget.background": "#FAFAFA",
    "editorHoverWidget.border": "#00000010",
    "editorIndentGuide.activeBackground": "#B0BEC5",
    "editorIndentGuide.background": "#B0BEC570",
    "editorInfo.foreground": "#6182B870",
    "editorLineNumber.activeForeground": "#758a95",
    "editorLineNumber.foreground": "#CFD8DC",
    "editorLink.activeForeground": "#90A4AE",
    "editorMarkerNavigation.background": "#90A4AE05",
    "editorOverviewRuler.border": "#FAFAFA",
    "editorOverviewRuler.errorForeground": "#E5393540",
    "editorOverviewRuler.findMatchForeground": "#80CBC4",
    "editorOverviewRuler.infoForeground": "#6182B840",
    "editorOverviewRuler.warningForeground": "#E2931D40",
    "editorRuler.foreground": "#B0BEC5",
    "editorSuggestWidget.background": "#FAFAFA",
    "editorSuggestWidget.border": "#00000010",
    "editorSuggestWidget.foreground": "#90A4AE",
    "editorSuggestWidget.highlightForeground": "#80CBC4",
    "editorSuggestWidget.selectedBackground": "#CCD7DA50",
    "editorWarning.foreground": "#E2931D70",
    "editorWhitespace.foreground": "#90A4AE40",
    "editorWidget.background": "#FAFAFA",
    "editorWidget.border": "#80CBC4",
    "editorWidget.resizeBorder": "#80CBC4",
    "extensionBadge.remoteForeground": "#90A4AE",
    "extensionButton.prominentBackground": "#91B85990",
    "extensionButton.prominentForeground": "#90A4AE",
    "extensionButton.prominentHoverBackground": "#91B859",
    "focusBorder": "#FFFFFF00",
    "foreground": "#90A4AE",
    "gitDecoration.conflictingResourceForeground": "#E2931D90",
    "gitDecoration.deletedResourceForeground": "#E5393590",
    "gitDecoration.ignoredResourceForeground": "#758a9590",
    "gitDecoration.modifiedResourceForeground": "#6182B890",
    "gitDecoration.untrackedResourceForeground": "#91B85990",
    "input.background": "#EEEEEE",
    "input.border": "#00000010",
    "input.foreground": "#90A4AE",
    "input.placeholderForeground": "#90A4AE60",
    "inputOption.activeBackground": "#90A4AE30",
    "inputOption.activeBorder": "#90A4AE30",
    "inputValidation.errorBorder": "#E53935",
    "inputValidation.infoBorder": "#6182B8",
    "inputValidation.warningBorder": "#E2931D",
    "list.activeSelectionBackground": "#FAFAFA",
    "list.activeSelectionForeground": "#80CBC4",
    "list.dropBackground": "#E5393580",
    "list.focusBackground": "#90A4AE20",
    "list.focusForeground": "#90A4AE",
    "list.highlightForeground": "#80CBC4",
    "list.hoverBackground": "#FAFAFA",
    "list.hoverForeground": "#B1C7D3",
    "list.inactiveSelectionBackground": "#CCD7DA50",
    "list.inactiveSelectionForeground": "#80CBC4",
    "listFilterWidget.background": "#CCD7DA50",
    "listFilterWidget.noMatchesOutline": "#CCD7DA50",
    "listFilterWidget.outline": "#CCD7DA50",
    "menu.background": "#FAFAFA",
    "menu.foreground": "#90A4AE",
    "menu.selectionBackground": "#CCD7DA50",
    "menu.selectionBorder": "#CCD7DA50",
    "menu.selectionForeground": "#80CBC4",
    "menu.separatorBackground": "#90A4AE",
    "menubar.selectionBackground": "#CCD7DA50",
    "menubar.selectionBorder": "#CCD7DA50",
    "menubar.selectionForeground": "#80CBC4",
    "notebook.focusedCellBorder": "#80CBC4",
    "notebook.inactiveFocusedCellBorder": "#80CBC450",
    "notificationLink.foreground": "#80CBC4",
    "notifications.background": "#FAFAFA",
    "notifications.foreground": "#90A4AE",
    "panel.background": "#FAFAFA",
    "panel.border": "#FAFAFA60",
    "panel.dropBackground": "#90A4AE",
    "panelTitle.activeBorder": "#80CBC4",
    "panelTitle.activeForeground": "#000000",
    "panelTitle.inactiveForeground": "#90A4AE",
    "peekView.border": "#00000020",
    "peekViewEditor.background": "#EEEEEE",
    "peekViewEditor.matchHighlightBackground": "#80CBC440",
    "peekViewEditorGutter.background": "#EEEEEE",
    "peekViewResult.background": "#EEEEEE",
    "peekViewResult.matchHighlightBackground": "#80CBC440",
    "peekViewResult.selectionBackground": "#758a9570",
    "peekViewTitle.background": "#EEEEEE",
    "peekViewTitleDescription.foreground": "#90A4AE60",
    "pickerGroup.border": "#FFFFFF1a",
    "pickerGroup.foreground": "#80CBC4",
    "progressBar.background": "#80CBC4",
    "quickInput.background": "#FAFAFA",
    "quickInput.foreground": "#758a95",
    "quickInput.list.focusBackground": "#90A4AE20",
    "sash.hoverBorder": "#80CBC450",
    "scrollbar.shadow": "#00000020",
    "scrollbarSlider.activeBackground": "#80CBC4",
    "scrollbarSlider.background": "#90A4AE20",
    "scrollbarSlider.hoverBackground": "#90A4AE10",
    "selection.background": "#CCD7DA80",
    "settings.checkboxBackground": "#FAFAFA",
    "settings.checkboxForeground": "#90A4AE",
    "settings.dropdownBackground": "#FAFAFA",
    "settings.dropdownForeground": "#90A4AE",
    "settings.headerForeground": "#80CBC4",
    "settings.modifiedItemIndicator": "#80CBC4",
    "settings.numberInputBackground": "#FAFAFA",
    "settings.numberInputForeground": "#90A4AE",
    "settings.textInputBackground": "#FAFAFA",
    "settings.textInputForeground": "#90A4AE",
    "sideBar.background": "#FAFAFA",
    "sideBar.border": "#FAFAFA60",
    "sideBar.foreground": "#758a95",
    "sideBarSectionHeader.background": "#FAFAFA",
    "sideBarSectionHeader.border": "#FAFAFA60",
    "sideBarTitle.foreground": "#90A4AE",
    "statusBar.background": "#FAFAFA",
    "statusBar.border": "#FAFAFA60",
    "statusBar.debuggingBackground": "#9C3EDA",
    "statusBar.debuggingForeground": "#FFFFFF",
    "statusBar.foreground": "#7E939E",
    "statusBar.noFolderBackground": "#FAFAFA",
    "statusBarItem.activeBackground": "#E5393580",
    "statusBarItem.hoverBackground": "#90A4AE20",
    "statusBarItem.remoteBackground": "#80CBC4",
    "statusBarItem.remoteForeground": "#000000",
    "tab.activeBackground": "#FAFAFA",
    "tab.activeBorder": "#80CBC4",
    "tab.activeForeground": "#000000",
    "tab.activeModifiedBorder": "#758a95",
    "tab.border": "#FAFAFA",
    "tab.inactiveBackground": "#FAFAFA",
    "tab.inactiveForeground": "#758a95",
    "tab.inactiveModifiedBorder": "#89221f",
    "tab.unfocusedActiveBorder": "#90A4AE",
    "tab.unfocusedActiveForeground": "#90A4AE",
    "tab.unfocusedActiveModifiedBorder": "#b72d2a",
    "tab.unfocusedInactiveModifiedBorder": "#89221f",
    "terminal.ansiBlack": "#000000",
    "terminal.ansiBlue": "#6182B8",
    "terminal.ansiBrightBlack": "#90A4AE",
    "terminal.ansiBrightBlue": "#6182B8",
    "terminal.ansiBrightCyan": "#39ADB5",
    "terminal.ansiBrightGreen": "#91B859",
    "terminal.ansiBrightMagenta": "#9C3EDA",
    "terminal.ansiBrightRed": "#E53935",
    "terminal.ansiBrightWhite": "#FFFFFF",
    "terminal.ansiBrightYellow": "#E2931D",
    "terminal.ansiCyan": "#39ADB5",
    "terminal.ansiGreen": "#91B859",
    "terminal.ansiMagenta": "#9C3EDA",
    "terminal.ansiRed": "#E53935",
    "terminal.ansiWhite": "#FFFFFF",
    "terminal.ansiYellow": "#E2931D",
    "terminalCursor.background": "#000000",
    "terminalCursor.foreground": "#E2931D",
    "textLink.activeForeground": "#90A4AE",
    "textLink.foreground": "#80CBC4",
    "titleBar.activeBackground": "#FAFAFA",
    "titleBar.activeForeground": "#90A4AE",
    "titleBar.border": "#FAFAFA60",
    "titleBar.inactiveBackground": "#FAFAFA",
    "titleBar.inactiveForeground": "#758a95",
    "tree.indentGuidesStroke": "#B0BEC5",
    "widget.shadow": "#00000020"
  },
  "displayName": "Material Theme Lighter",
  "name": "material-theme-lighter",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "settings": {
        "background": "#FAFAFA",
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "string",
      "settings": {
        "foreground": "#91B859"
      }
    },
    {
      "scope": "punctuation, constant.other.symbol",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "constant.character.escape, text.html constant.character.entity.named",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "constant.language.boolean",
      "settings": {
        "foreground": "#FF5370"
      }
    },
    {
      "scope": "constant.numeric",
      "settings": {
        "foreground": "#F76D47"
      }
    },
    {
      "scope": "variable, variable.parameter, support.variable, variable.language, support.constant, meta.definition.variable entity.name.function, meta.function-call.arguments",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "keyword.other",
      "settings": {
        "foreground": "#F76D47"
      }
    },
    {
      "scope": "keyword, modifier, variable.language.this, support.type.object, constant.language",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "entity.name.function, support.function",
      "settings": {
        "foreground": "#6182B8"
      }
    },
    {
      "scope": "storage.type, storage.modifier, storage.control",
      "settings": {
        "foreground": "#9C3EDA"
      }
    },
    {
      "scope": "support.module, support.node",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#E53935"
      }
    },
    {
      "scope": "support.type, constant.other.key",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "entity.name.type, entity.other.inherited-class, entity.other",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "comment",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "comment punctuation.definition.comment, string.quoted.docstring",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "punctuation",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "entity.name, entity.name.type.class, support.type, support.class, meta.use",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "variable.object.property, meta.field.declaration entity.name.function",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "meta.definition.method entity.name.function",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "meta.function entity.name.function",
      "settings": {
        "foreground": "#6182B8"
      }
    },
    {
      "scope": "template.expression.begin, template.expression.end, punctuation.definition.template-expression.begin, punctuation.definition.template-expression.end",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "meta.embedded, source.groovy.embedded, meta.template.expression",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "entity.name.tag.yaml",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "meta.object-literal.key, meta.object-literal.key string, support.type.property-name.json",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "constant.language.json",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "entity.other.attribute-name.class",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "entity.other.attribute-name.id",
      "settings": {
        "foreground": "#F76D47"
      }
    },
    {
      "scope": "source.css entity.name.tag",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "support.type.property-name.css",
      "settings": {
        "foreground": "#8796B0"
      }
    },
    {
      "scope": "meta.tag, punctuation.definition.tag",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "entity.name.tag",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "entity.other.attribute-name",
      "settings": {
        "foreground": "#9C3EDA"
      }
    },
    {
      "scope": "punctuation.definition.entity.html",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "markup.heading",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "text.html.markdown meta.link.inline, meta.link.reference",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "text.html.markdown beginning.punctuation.definition.list",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "markup.italic",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#E53935"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#E53935"
      }
    },
    {
      "scope": "markup.bold markup.italic, markup.italic markup.bold",
      "settings": {
        "fontStyle": "italic bold",
        "foreground": "#E53935"
      }
    },
    {
      "scope": "markup.fenced_code.block.markdown punctuation.definition.markdown",
      "settings": {
        "foreground": "#91B859"
      }
    },
    {
      "scope": "markup.inline.raw.string.markdown",
      "settings": {
        "foreground": "#91B859"
      }
    },
    {
      "scope": "keyword.other.definition.ini",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "entity.name.section.group-title.ini",
      "settings": {
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "source.cs meta.class.identifier storage.type",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "source.cs meta.method.identifier entity.name.function",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "source.cs meta.method-call meta.method, source.cs entity.name.function",
      "settings": {
        "foreground": "#6182B8"
      }
    },
    {
      "scope": "source.cs storage.type",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "source.cs meta.method.return-type",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "source.cs meta.preprocessor",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "source.cs entity.name.type.namespace",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "meta.jsx.children, SXNested",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "support.class.component",
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": "source.cpp meta.block variable.other",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "source.python meta.member.access.python",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "source.python meta.function-call.python, meta.function-call.arguments",
      "settings": {
        "foreground": "#6182B8"
      }
    },
    {
      "scope": "meta.block",
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": "entity.name.function.call",
      "settings": {
        "foreground": "#6182B8"
      }
    },
    {
      "scope": "source.php support.other.namespace, source.php meta.use support.class",
      "settings": {
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": "constant.keyword",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "foreground": "#6182B8"
      }
    },
    {
      "settings": {
        "background": "#FAFAFA",
        "foreground": "#90A4AE"
      }
    },
    {
      "scope": [
        "constant.other.placeholder"
      ],
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": [
        "markup.deleted"
      ],
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": [
        "markup.inserted"
      ],
      "settings": {
        "foreground": "#91B859"
      }
    },
    {
      "scope": [
        "markup.underline"
      ],
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": [
        "keyword.control"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": [
        "variable.parameter"
      ],
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": [
        "variable.parameter.function.language.special.self.python"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#E53935"
      }
    },
    {
      "scope": [
        "constant.character.format.placeholder.other.python"
      ],
      "settings": {
        "foreground": "#F76D47"
      }
    },
    {
      "scope": [
        "markup.quote"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#39ADB5"
      }
    },
    {
      "scope": [
        "markup.fenced_code.block"
      ],
      "settings": {
        "foreground": "#90A4AE90"
      }
    },
    {
      "scope": [
        "punctuation.definition.quote"
      ],
      "settings": {
        "foreground": "#FF5370"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#9C3EDA"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#E2931D"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#F76D47"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#E53935"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#916b53"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#6182B8"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#FF5370"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#9C3EDA"
      }
    },
    {
      "scope": [
        "meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#91B859"
      }
    }
  ],
  "type": "light"
});

export { materialThemeLighter as default };
